import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fc5e930"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "control editable" }
const _hoisted_2 = { class: "field-controls" }
const _hoisted_3 = {
  key: 0,
  class: "table-controls row no-gutters",
  style: { opacity: 1 }
}
const _hoisted_4 = { class: "table-field ellipsis" }
const _hoisted_5 = { class: "grid-empty text-center" }
const _hoisted_6 = ["alt"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" label "),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label", {}, undefined, true),
      _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
    ]),
    _createCommentVNode(" table grid "),
    ($props.df.fieldtype == 'Table')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.table_columns, ([tf, size], i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "table-column",
              style: _normalizeStyle({ width: size * 10 + '%' }),
              key: i
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(tf.label), 1 /* TEXT */)
            ], 4 /* STYLE */))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", {
        src: "/assets/frappe/images/ui-states/grid-empty-state.svg",
        alt: _ctx.__('Grid Empty State'),
        class: "grid-empty-illustration"
      }, null, 8 /* PROPS */, _hoisted_6),
      _createTextVNode(" " + _toDisplayString(_ctx.__("No Data")), 1 /* TEXT */)
    ]),
    _createCommentVNode(" description "),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "mt-2 description",
          innerHTML: $props.df.description
        }, null, 8 /* PROPS */, _hoisted_7))
      : _createCommentVNode("v-if", true)
  ]))
}